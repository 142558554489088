<template>
<v-sheet >
<v-container>
  <v-row>
    hello world
  </v-row>
</v-container>
</v-sheet>
</template>


<script>
// import merchantselect from "@/components/filters/autocompletes/MerchantSelect.vue";
// import barchart from "@/components/charts/ChartJs/BarChart.vue";
// import reportService from "@/services/ReportService";

export default {
  components: {
  },
  data: () => ({
   
    loading: false,
    loadingDownload: false,
    headers: [
      {
        text: "Merchant",
        sortable: false,
        value: "merchant",
        align: "start",
      },
      {
        text: "Product",
        sortable: false,
        value: "product",
        align: "start",
      }
    ],
    charts: [],
  }),
  mounted() {
    
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    merchants() {
      return this.$store.getters["filter/filterRequest"].merchants;
    },
  },
  methods: {
    async refresh() {

    //   this.$store.dispatch("util/setLoading", {
    //     visible: true,
    //     text: "Fetching report",
    //   });
    //   let result = await reportService.GetMerchantSalesReportDetailed(
    //     merchants,
    //     dateStart,
    //     dateEnd
    //   );

    //     if(result == null){
    //       this.$store.dispatch("util/resetLoading");
    //       const snackbar = { show: true, message: "", errors: ["Report could not be loaded"]  }
    //       this.$store.dispatch("util/setSnackbar", snackbar)
    //       return
    //     }
          
    // this.charts = []
    //   this.charts = await reportService.GetMerchantSalesReportDetailedCharts(
    //     merchants,
    //     dateStart,
    //     dateEnd,
    //     result.cacheRecordKey
    //   );
    //   this.$store.dispatch("util/resetLoading");

    //   this.items = result.report;
    }
  }
}
</script>




