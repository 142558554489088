import Vue from "vue";

export default new (class SegmentService {

  
  async GetMonthlyQuantityLinechart(year){
    const path = `/api/reports/MonthlyQuantityLinechart/${year}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get",
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error)
      });

    return result;
  }    
    
  async DownloadMerchantSalesReportDetailed(merchants, dateStart, dateEnd){
    const path = `/api/reports/DownloadMerchantSalesReportDetailed?dateStart=${dateStart}&dateEnd=${dateEnd}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      responseType: "blob",
      data: merchants
    })
      .then((response) => {
        result = response.data;
        const url_1 = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url_1;
        var reportName = "MerchantSalesReport";
        link.setAttribute("download", `${reportName}_${dateStart}--${dateEnd}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetMerchantSalesReportDetailed(merchants, dateStart, dateEnd){
    const path = `/api/reports/MerchantSalesReportDetailed?dateStart=${dateStart}&dateEnd=${dateEnd}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: merchants
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error)
      });

    return result;
  }    
  
  async GetMerchantSalesReportDetailedCharts(merchants, dateStart, dateEnd, cacheRecordKey){
    const path = `/api/reports/MerchantSalesReportDetailedCharts?dateStart=${dateStart}&dateEnd=${dateEnd}&cacheRecordKey=${cacheRecordKey}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: merchants
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }     

  
  


  
})();
