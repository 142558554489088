<template>
  <div>
    <v-sheet color="grey lighten-3" min-height="10vh">
      <v-container fill-height>
        <v-row dense justify="start">
          <div class="transition-swing text-h4 mt-10 mb-2">Reports</div>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="grey lighten-2" >
          <!-- <v-tabs v-model="tab" color="info" class="py-2" centered show-arrows background-color="grey lighten-2">
            <v-tab v-for="(t, i) in tabs" :key="i">
              {{ t.tab }}
            </v-tab>
          </v-tabs> -->

      <component v-bind:is="activeComponent"></component>
    </v-sheet>
  </div>
</template>


<script>
import MerchantIndexReportView from "@/components/reports/MerchantIndexReportView";
import SalesrepReportView from "@/components/reports/SalesrepReportView";

export default {
  components: {
    MerchantIndexReportView,
    SalesrepReportView
  },
  data: () => ({
    dateFrom: null,
    dateFromMenu: false,
    dateTo: null,
    dateToMenu: false,

    tab: 0,
    tabs: [
      { tab: "Merchants", component: "MerchantIndexReportView" },
      { tab: "Salesrep", component: "SalesrepReportView" },
    ],
  }),
  mounted() {
    this.setPeriod("ytd");
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    activeComponent() {
      return this.tabs[this.tab].component;
    },
  },
  methods: {
    setPeriod(val) {
      let today = new Date();
      switch (val) {
        case "ytd":
          this.dateFrom = formatDate(new Date(today.getFullYear(), 0, 1));
          this.dateTo = formatDate(today);
          break;
        case "lytd":
          this.dateFrom = formatDate(new Date(today.getFullYear() - 1, 0, 1));
          this.dateTo = formatDate(addDays(today, -365));
          break;
        case "l12":
          this.dateFrom = formatDate(addDays(today, -365));
          this.dateTo = formatDate(today);
          break;
        default:
          break;
      }
    },
  },
};

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
</script>




