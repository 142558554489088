<template>
<div>
    <v-sheet >
      <v-container>
        <v-row>
          <v-col lg="3" md="6" sm="6" cols="12">
            <merchantselect :enableSelectAll="true" />
          </v-col>
          <v-col lg="4" md="6" cols="12">
            <v-card height="120">
              <v-card-subtitle class="my-0"> Date range </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-menu
                      v-model="dateFromMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFrom"
                          label="Date from"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFrom"
                        @input="dateFromMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-menu
                      v-model="dateToMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateTo"
                          label="Date to"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateTo"
                        @input="dateToMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
            <v-btn-toggle @change="setPeriod" color="primary" group dense>
              <v-btn value="ytd" small>YTD</v-btn>

              <v-btn value="lytd" small>LYTD</v-btn>

              <v-btn value="l12" small>L12</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col> </v-col>
        </v-row>

         <v-divider class="my-2" />
        <v-row justify="start">
          <v-col cols="2">
            <v-btn color="primary" @click="refresh" small> Refresh </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="grey lighten-5">
      <v-container>
        <v-row justify="center">
          <v-col lg="10" md="6" cols="12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              item-key="id"
              :search="search"
              v-if="items.length > 0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-container>
                    <v-row class="mt-1" justify="center" align="top">
                      <v-toolbar-title>Report</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </v-row>
                  </v-container>
                </v-toolbar>
              </template>
              <template v-slot:footer>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    color="primary"
                    @click="downloadReport"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="charts">
          <v-col lg="6" cols="12" v-for="(c, i) in charts" :key="i">
            <v-card height="425" class="pa-2">
              <barchart :id="i" :chart="c"  :width="150" :height="300"/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
</div>
</template>


<script>
import merchantselect from "@/components/filters/autocompletes/MerchantSelect.vue";
import barchart from "@/components/charts/ChartJs/BarChart.vue";
import reportService from "@/services/ReportService";

export default {
  components: {
    merchantselect,
    barchart
  },
  data: () => ({
    dateFrom: null,
    dateFromMenu: false,
    dateTo: null,
    dateToMenu: false,
    loading: false,
    loadingDownload: false,
    items: [],
    merchantAggregated: [],
    search: "",
    headers: [
      {
        text: "Merchant",
        sortable: false,
        value: "merchant",
        align: "start",
      },
      {
        text: "Product",
        sortable: false,
        value: "product",
        align: "start",
      },
      {
        text: "Quantity",
        sortable: false,
        value: "quantity_Formatted",
        align: "start",
      },
      {
        text: "Revenue",
        width:120,
        sortable: false,
        value: "amount_Formatted",
        align: "start",
      },
      {
        text: "Quantity Identified",
        sortable: false,
        value: "quantityIdentified_Formatted",
        align: "start",
      },
      {
        text: "Revenue Identified",
        width:120,
        sortable: false,
        value: "amountIdentified_Formatted",
        align: "start",
      },
      {
        text: "Quantity SÅIFA",
        sortable: false,
        value: "quantitySAIFA_Formatted",
        align: "start",
      },
      {
        text: "Revenue SÅIFA",
        width:120,
        sortable: false,
        value: "amountSAIFA_Formatted",
        align: "start",
      },
      {
        text: "Quantity FTG",
        sortable: false,
        value: "quantityFTG_Formatted",
        align: "start",
      },
      {
        text: "Revenue FTG",
        width:120,
        sortable: false,
        value: "amountFTG_Formatted",
        align: "start",
      },
      {
        text: "Quantity Private",
        sortable: false,
        value: "quantityPrivate_Formatted",
        align: "start",
      },
      {
        text: "Revenue Private",
        width:120,
        sortable: false,
        value: "amountPrivate_Formatted",
        align: "start",
      },
      {
        text: "Quantity Anonymous",
        sortable: false,
        value: "quantityAnonymous_Formatted",
        align: "start",
      },
      {
        text: "Revenue Anonymous",
        width:120,
        sortable: false,
        value: "amountAnonymous_Formatted",
        align: "start",
      },
    ],
    charts: [],
  }),
  mounted() {
    this.setPeriod("ytd");
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    merchants() {
      return this.$store.getters["filter/filterRequest"].merchants;
    },
  },
  methods: {
    setPeriod(val) {
      let today = new Date();
      switch (val) {
        case "ytd":
          this.dateFrom = formatDate(new Date(today.getFullYear(), 0, 1));
          this.dateTo = formatDate(today);
          break;
        case "lytd":
          this.dateFrom = formatDate(new Date(today.getFullYear() - 1, 0, 1));
          this.dateTo = formatDate(addDays(today, -365));
          break;
        case "l12":
          this.dateFrom = formatDate(addDays(today, -365));
          this.dateTo = formatDate(today);
          break;
        default:
          break;
      }
    },
    async refresh() {
      let dateStart = this.dateFrom;
      let dateEnd = this.dateTo;
      let merchants = this.merchants;

      this.$store.dispatch("util/setLoading", {
        visible: true,
        text: "Fetching report",
      });
      let result = await reportService.GetMerchantSalesReportDetailed(
        merchants,
        dateStart,
        dateEnd
      );

        if(result == null){
          this.$store.dispatch("util/resetLoading");
          const snackbar = { show: true, message: "", errors: ["Report could not be loaded"]  }
          this.$store.dispatch("util/setSnackbar", snackbar)
          return
        }
          
    this.charts = []
      this.charts = await reportService.GetMerchantSalesReportDetailedCharts(
        merchants,
        dateStart,
        dateEnd,
        result.cacheRecordKey
      );
      this.$store.dispatch("util/resetLoading");

      this.items = result.report;
    },

    async downloadReport() {
      let dateStart = this.dateFrom;
      let dateEnd = this.dateTo;
      let merchants = this.merchants;

      this.$store.dispatch("util/setLoading", {
        visible: true,
        text: "Generating Excel file",
      });
      await reportService.DownloadMerchantSalesReportDetailed(
        merchants,
        dateStart,
        dateEnd
      );
      this.$store.dispatch("util/resetLoading");
    },
  },
};

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
</script>




